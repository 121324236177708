import { useCallback, useRef, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  useRidersQueryLazyQuery,
  Rider,
  ScheduleQueryWithRideQuery,
} from 'generated/graphql';
import { useRiderProfileNavigation } from '../../hooks/useRiderProfileNavigation';
import ZeroStateText from '../ZeroStateText';
import { dateOnly, personName, validPhone } from '@onwardcare/core';
import { useStyles } from './rideFormStyles';
import {
  FormControl,
  MenuItem,
  Link,
  FormHelperText,
  TextField,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import PersonImage from 'components/PersonImage';
import { Link as RouterLink } from 'react-router-dom';
import { Control } from 'react-hook-form';

type RiderInputComponentProps = {
  control: Control;
  isUpdate: boolean;
  isEhrPatientContext: boolean;
  ridersList: Rider[] | null | undefined;
  defaultRide: ScheduleQueryWithRideQuery['ride'];
  errors: any;
  isRideshare: boolean;
  selectedRider: Rider | undefined;
  watchRide: any;
  watchTransportType: string;
  isCreate: boolean;
  setRidersList: (data: any) => void;
};

const RiderInputComponent = ({
  control,
  isUpdate,
  isEhrPatientContext,
  ridersList,
  defaultRide,
  errors,
  isRideshare,
  selectedRider,
  watchRide,
  watchTransportType,
  isCreate,
  setRidersList,
}: RiderInputComponentProps) => {
  const { formControl } = useStyles();
  const { generateFromParams } = useRiderProfileNavigation();
  const riderInputRef = useRef<HTMLInputElement>(null);
  const urlFromParams = generateFromParams();

  const [getRiders] = useRidersQueryLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ riders }) => {
      setRidersList(riders);
    },
  });

  const getRidersOnChange = useCallback(
    (_, newValue: any, reason: string) => {
      // We only want to fetch riders if the user is typing into the input field
      // or if they cleared it so that we fetch all riders.
      if (reason === 'input' || reason === 'clear') {
        getRiders({ variables: { query: newValue } });
      }
    },
    [getRiders],
  );

  const handleRiderChange = useCallback(
    onChange => (_: any, newValue: Rider) => {
      onChange(newValue?.id);
    },
    [],
  );

  const RiderErrors = useMemo(() => {
    console.log('validation errors:', errors);

    const riderErrorTypeIsCustom = errors.ride?.riderId?.type === 'custom';
    const riderHasNoPhoneForRideshare =
      isRideshare && selectedRider && !validPhone(selectedRider?.phone);
    const displayEditRiderLink =
      riderErrorTypeIsCustom || riderHasNoPhoneForRideshare;
    const riderErrorMessage = riderErrorTypeIsCustom
      ? errors.ride?.riderId?.message
      : 'Rider must have a valid SMS-Enabled Mobile Phone Number when an Uber / Lyft transport type is selected.';
    if (displayEditRiderLink) {
      riderInputRef?.current?.focus();
      return <FormHelperText error>{riderErrorMessage}</FormHelperText>;
    }
    if (errors.ride?.riderId) {
      return (
        <FormHelperText error>{`Select a ${
          watchRide.rideType === 'delivery' ? 'customer' : 'rider'
        }`}</FormHelperText>
      );
    }

    return null;
  }, [selectedRider, errors.ride, watchTransportType, watchRide.rideType]);

  const RiderLink = useMemo(() => {
    return (
      <div style={{ display: 'flex' }}>
        {isEhrPatientContext === false && (
          <div style={{ marginRight: '1em' }}>
            <Link
              component={RouterLink}
              to={`/riders/select?${urlFromParams}`}
              variant="body2"
            >
              Add rider
            </Link>
          </div>
        )}
        {selectedRider ? (
          <Link
            component={RouterLink}
            to={`/riders/${selectedRider.id}?${urlFromParams}`}
            variant="body2"
          >
            Edit Rider
          </Link>
        ) : null}
      </div>
    );
  }, [isEhrPatientContext, selectedRider, urlFromParams]);

  return (
    <>
      <FormControl className={formControl}>
        <Controller
          control={control}
          name="ride.riderId"
          rules={{ required: true }}
          onChange={([, data]: any) => data}
          render={({ onChange, ...props }) => {
            return (
              <Autocomplete
                // TODO: Investigate a more efficient approach for this issue.
                // The component does not automatically re-render when the rider list is updated,
                // so the key prop is used to trigger a re-render.
                // The selected rider ID is used as the key to maintain uniqueness.
                // The underlying issue seems to be that riderInputRef interferes with the component's re-rendering behavior.
                key={selectedRider?.id}
                disabled={isUpdate || isEhrPatientContext}
                getOptionLabel={option => {
                  // true means that the user selected the rider from the list.
                  if (typeof option === 'object') {
                    return `${option?.firstName} ${option?.lastName}`;
                  }

                  const riderInList = ridersList?.find(
                    (r: any) => r.id === option,
                  );

                  const { rider } = defaultRide || {};

                  if (riderInList) {
                    const { firstName, lastName } = riderInList;
                    return `${firstName} ${lastName}`;
                  } else if (rider) {
                    return `${rider.firstName} ${rider.lastName}`;
                  }

                  return '';
                }}
                data-testid="rider-autocomplete"
                value={props.value}
                options={ridersList || []}
                getOptionSelected={(option, value) => option.id === value}
                filterOptions={x => x} // don't filter
                filterSelectedOptions
                autoComplete
                includeInputInList
                onInputChange={getRidersOnChange}
                onChange={handleRiderChange(onChange)}
                renderOption={option => {
                  return (
                    <MenuItem value={option.id} key={option.id}>
                      <ListItemAvatar>
                        <PersonImage person={option} size="medium" />
                      </ListItemAvatar>
                      <ListItemText
                        id={option.id}
                        primary={personName(option)}
                        secondary={
                          <div>
                            {option.dob ? `DOB: ${dateOnly(option.dob)}` : ''}
                            <div style={{ display: 'flex' }}>
                              {option.paymentMethods.length ? (
                                <ZeroStateText>{`${option.paymentMethods?.[0]?.cardBrand?.toUpperCase()} ** ${
                                  option.paymentMethods?.[0].cardLast4
                                }`}</ZeroStateText>
                              ) : null}
                            </div>
                          </div>
                        }
                      />
                    </MenuItem>
                  );
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      inputRef={riderInputRef}
                      data-testid="rider-autocomplete-text"
                      label={'Rider'}
                      error={!!errors.ride?.riderId}
                      name={'Rider'}
                      helperText={RiderErrors}
                    />
                  );
                }}
              />
            );
          }}
        />
      </FormControl>
      {isCreate && (
        <FormControl className={formControl}>{RiderLink}</FormControl>
      )}
    </>
  );
};

export default RiderInputComponent;
