import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { CheckboxProps } from './types';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import useStyles from './styles';

interface RideshareCheckboxComponentProps extends CheckboxProps {
  name: string;
  labelText: string;
  tooltipText: string;
  fieldToClear?: string;
}

const _RideshareCheckboxComponent: React.FC<RideshareCheckboxComponentProps> = ({
  control,
  disabled,
  name,
  labelText,
  tooltipText,
  fieldToClear,
}) => {
  const classes = useStyles();

  const handleOnClick = (onChange: (value: boolean) => void) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (fieldToClear) {
      control.setValue(fieldToClear, false);
    }
    onChange(event.target.checked);
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ onChange, value }) => (
              <Checkbox
                onChange={handleOnClick(onChange)}
                checked={Boolean(value)}
                disabled={disabled}
              />
            )}
          />
        }
        label={
          <LabelWithTooltip labelText={labelText} tooltipText={tooltipText} />
        }
      />
    </FormControl>
  );
};

export const RideshareCheckboxComponent = React.memo(
  _RideshareCheckboxComponent,
);
